function checkParticipantRadioButtons() {
  let $radios = $('input[type=radio][name="course_registration_form[selected_participant]"]');
  $radios.filter(':not(:checked)').each((idx,el) => {
    $($(el).data('toggle')).addClass('d-none');
  });
  let $checked = $radios.filter(':checked');
  $($checked.data('toggle')).removeClass('d-none');
}

function checkGuardianRadioButtons() {
  let $radios = $('input[type=radio][name="course_registration_form[selected_guardian]"]');
  $radios.filter(':not(:checked)').each((idx,el) => {
    $($(el).data('toggle')).addClass('d-none');
  });
  let $checked = $radios.filter(':checked');
  $($checked.data('toggle')).removeClass('d-none');
}

$(function() {
  $('input[type=radio][name="course_registration_form[selected_participant]"]').on('change', checkParticipantRadioButtons);
  $('input[type=radio][name="course_registration_form[selected_guardian]"]').on('change', checkGuardianRadioButtons);
});
