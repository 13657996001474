import iziToast from "izitoast"
import flatpickr from "flatpickr"
import { Italian } from "flatpickr/dist/l10n/it.js"
import MediumEditor from 'medium-editor'
import AutoNumeric from 'autonumeric'

const app = {
  initOnLoad: function() {
    app.initMediumEditor();

    $('[data-rel="tooltip"]').tooltip({});

    $('[data-toggle="popover"]').popover();

    $('[data-provide="flatpickr"]').each(function () {
      app.initFlatPickr($(this));
    });
    $('[data-rel="bootstrap-switch"]').bootstrapToggle({});

    // datetimepicker
    $('[data-provide="datetimepicker"]').datetimepicker({
      format: 'L LT',
      showClear: true,
      icons: {
        time: "fa fa-clock-o",
        date: "fa fa-calendar",
        up: "fa fa-arrow-up",
        down: "fa fa-arrow-down",
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-screenshot',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }
    }).next().on('click', function(){
      $(this).prev().focus();
    });
    // datepicker
    $('[data-provide="datepicker"]').each(function(){
      var viewmode = $(this).data('view-mode') || 'days';
      $(this).datetimepicker({
        format: 'L',
        showClear: true,
        viewMode: viewmode,
        icons: {
          time: "fa fa-clock-o",
          date: "fa fa-calendar",
          up: "fa fa-arrow-up",
          down: "fa fa-arrow-down",
          previous: 'fa fa-chevron-left',
          next: 'fa fa-chevron-right',
          today: 'fa fa-screenshot',
          clear: 'fa fa-trash',
          close: 'fa fa-remove'
        }
      }).next().on('click', function(){
        $(this).prev().focus();
      });
    });

    // autoNumeric
    //$('input[data-role=money]').AutoNumeric('init', {aSep: '.', aDec: ','});
    // autoNumeric with specific options being passed
    new AutoNumeric.multiple('input[data-role="money"]', { aSep: '.', aDec: ',' });

    // auto-show auto-hide
    $('input[data-auto-show], input[data-auto-hide], select[data-auto-show], select[data-auto-hide]').each(function(index, el) {
      app.onChangeAutoShow.call(el);
    });

    // // Validating HH:MM
    if($('input[data-validate-hour]').length > 0){
      new Cleave('input[data-validate-hour]', {
        time: true,
        timePattern: ['h', 'm']
      });
    }
  },

  initMediumEditor: function(){
    new MediumEditor('.m-editor-light', {
      placeholder:{
        text: 'Inserisci testo...'
      },
      toolbar: {
        buttons: ['bold', 'italic']
      }
    });
    new MediumEditor('.m-editor', {
      placeholder:{
        text: 'Inserisci testo...'
      },
      toolbar: {
        buttons: ['h2', 'h3', 'bold', 'italic', 'anchor']
      }
    });
    new MediumEditor('.tos-editor, .ui-page-editor', {
      placeholder:{
        text: 'Inserisci testo...'
      },
      toolbar: {
        buttons: ['h1', 'h2', 'h3', 'unorderedlist', 'orderedlist', 'bold', 'italic', 'anchor']
      }
    });
  },

  initFlatPickr: function($element){
    var options = {
      locale: Italian,
      altInput: true,
      altFormat: "d/m/Y"
    }
    if($element.data('options')){
      options = {...options, ...$element.data('options') }
    }
    flatpickr($element, options);
  },

  onChangeAutoShow: function() {
    var $this = $(this);
    var showOnChecked = true;
    var rel = $this.data('auto-show');
    var $el = $(rel);

    if ( $this.is(':checked') || $this.find(":selected").val()=="true" ) {
      showOnChecked ? $el.show() : $el.hide();
    } else {
      showOnChecked ? $el.hide() : $el.show();
      $el.find('input[type="text"], input[type="number"]').val('');
      $el.find('input[type="checkbox"]').prop('checked', false);
      $el.find('input[type="radio"]').prop('checked', false);
      $el.find('select').val('');
    }
  },

  flashMessage: function(name, msg, position){
    var obj = {
      message: msg,
      timeout: 8000,
      position: position
    }
    if(name == 'error'){
      obj.title = 'Errore'
      obj.color = 'red'
    }else if(name == 'notice'){
      obj.title = 'Successo'
      obj.color = 'green'
    }else if(name == 'alert'){
      obj.title = 'Attenzione'
      obj.color = 'yellow'
    }
    iziToast.show(obj);
  }
}

export default app;
