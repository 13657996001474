function onOpenMenu() {
  // window.addEventListener('scroll', noScroll);
  $("body").css("overflow","hidden");
}

function onCloseMenu() {
  // window.removeEventListener('scroll', noScroll);
  $("body").css("overflow","auto");
}

function noScroll() {
  window.scrollTo(0, 0);
}

$(function() {
  $('#members_menu')
    .on('shown.bs.collapse', onOpenMenu)
    .on('hidden.bs.collapse', onCloseMenu);
});
